* {
  font-display: swap;
  font-family: Raleway, sans-serif;
}

html, body, img, header, ul, li, p, svg, div, nav, h1, main, a, label, input, .menubars-svg, #menubars-svg, .container.slider-wrapper, .img-list, .image-item {
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: Raleway, sans-serif;
}
/*# sourceMappingURL=index.405f58fc.css.map */
